
import { computed, watch, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';

import Toolbar from 'primevue/toolbar';

import { Icon } from '../../components/ui';
import { Paginator } from '../../components/common';
import { useRoute, useRouter } from 'vue-router';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Button,
    Icon,
    Toolbar,
    Checkbox,
    Paginator,
    ProgressSpinner
  },
  setup() {
    const { photosStore, photosStoreState } = useStores();

    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const isShow = ref(false);
    const downloadIDs = computed(() => {
      const filtered = photosStoreState.data.photos.data.filter(({ isSelected }) => isSelected);
      if (filtered.length) {
        return filtered.map(({ id }) => id);
      }
      return photosStoreState.data.photos_ids;
    });
    const processedIDs = computed(() => {
      const ids = {};
      photosStoreState.data.photos.data.forEach(({ id, processed }) => {
        ids[id] = processed;
      });
      return ids;
    });
    const datasetIDs = computed(() => photosStoreState.data.datasets.map(({ id }) => id));

    photosStore.init({ sectionID: route.params.photosID, per_page: 32, page: route.query.page });
    watch(route, () => {
      photosStore.init({ sectionID: route.params.photosID, per_page: 32, page: route.query.page });
    });

    const download = () => {
      photosStore.downloadPhoto({ photos: downloadIDs.value }, toast, t);
    };
    const markProcessed = () => {
      photosStore.markProcessedPhoto(processedIDs.value, toast, t);
    };

    function uploadFile(event) {
      const files = {};
      for (let index = 0; index < event.target.files.length; index++) {
        files[`photo[${index}]`] = event.target.files[index];
      }
      const body = {
        section_id: route.params.photosID,
        ...files
      };
      photosStore.uploadPhoto(body, toast, t);
    }

    const datasetUpload = (event) => {
      const files = {};
      for (let index = 0; index < event.target.files.length; index++) {
        files[`file[${index}]`] = event.target.files[index];
      }
      const body = {
        section_id: route.params.photosID,
        ...files
      };

      photosStore.uploadDataset(body, toast, t);
    };
    const datasetDownload = () => {
      photosStore.downloadDataset(route.params.photosID, toast, t);
    };
    const deleteAlbum = () => {
      photosStore.deleteAlbum({ sectionID: route.params.photosID }, toast, t, router);
    };
    return {
      photosStoreState,

      isShow,
      download,
      uploadFile,
      markProcessed,
      datasetUpload,
      datasetDownload,
      deleteAlbum
    };
  }
};
